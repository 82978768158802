.footer {
  width: 100%;

  background: #000;

  z-index: 2;
  position: relative;

  margin-bottom: auto;

  padding: 50px 0;
  margin-top: 100px;
}

.infoSection {
  display: flex;
}

.section,
.sectionList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.sectionHeader {
  letter-spacing: 2.5px;
  font-size: 24px;
}

.link,
.text,
.navLink {
  font-size: 16px;
  color: #ddd;
}

.link:hover,
.navLink:hover,
.activeLink {
  color: #fff;
}

.socialList {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  margin-top: 1vh;
}

.socialImage {
  filter: contrast(0.6);
}

.socialImage:hover {
  filter: contrast(1);
}

@media screen and (orientation: landscape), (min-width: 500px) {
  .infoSection {
    justify-content: center;
    flex-direction: row;
    column-gap: 4vw;
  }

  .sectionHeader {
    margin-bottom: 1vh;
  }
}

@media screen and (orientation: portrait) and (max-width: 500px) {
  .infoSection {
    flex-direction: column;
    row-gap: 2.5vh;
  }

  .socialImage {
    width: 24px !important;
    height: 24px !important;
  }

  .socialList {
    width: 60vw;
    max-width: 300px;
  }

  .link,
  .text {
    text-align: center;
  }

  .section {
    align-items: center;
  }

  .sectionHeader {
    text-align: center;

    margin-bottom: 0.25vh;
  }
}
