:root {
  --foreground-rgb: 255, 255, 256;
  --glow: linear-gradient(
    to bottom right,
    rgba(1, 65, 255, 0),
    rgb(16 211 255 / 20%),
    rgb(216 0 255 / 50%)
  );
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

:not(img)::-moz-selection {
  background: #05c3dd;
}

:not(img)::selection {
  background: #05c3dd;
}

img::selection {
  background: transparent;
}

img::moz-selection {
  background: transparent;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #fff;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

div[data-overlay-container='true'] {
  position: absolute;
  top: 0px;
  left: 0px;

  width: 100vw;
  height: 100vh;

  overflow-y: scroll;
  overflow-x: hidden;
}

.main {
  width: 100vw;
  height: fit-content;
  overflow: scroll;
}
