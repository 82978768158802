.navbar {
  position: fixed;

  z-index: 4;

  width: 100vw;
  min-height: 76px;

  padding: 20px 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: #fff;
}

.tab {
  font-size: 20px;
}

.navbar .inner {
  max-width: 1800px;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.tabList {
  margin-left: auto;

  display: flex;
  flex-direction: row;
  gap: 24px;
}

.navToggle {
  width: 48px;
  height: 48px;

  box-shadow: 0 0 3px hsl(0deg 0% 0% / 30%), 0 0 2px hsl(0deg 0% 0% / 30%);

  margin-left: auto;

  padding: 13px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.navToggle:hover {
  cursor: pointer;
}

.navToggle:not(.active):hover .one {
  transform: translateX(-2px);
}

.navToggle:not(.active):hover .two {
  transform: translateX(2px);
}

.navToggle.active .one {
  transform: translateY(6px) rotate(45deg);
}

.navToggle.active .two {
  transform: translateY(-5px) rotate(-45deg);
}

.navmenu {
  position: fixed;

  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;

  z-index: 3;
}

.navmenu .inner {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 12px;
}

.sectionItem {
  font-size: 40px;
}

.line {
  width: 100%;
  height: 1px;

  background: #000;
}

.link {
  color: #333;
}

.link:hover {
  color: #222;
}

.activeLink {
  color: #111 !important;
}

@media screen and (max-width: 600px) {
  .navmenu .inner {
    flex-direction: column;
  }

  .sectionList {
    padding: 12px 0 0 0;

    align-items: center;
  }
}

@media screen and (max-width: 500px) {
  .navbar {
    padding: 20px 16px;
  }
}

@media not (prefers-reduced-motion) {
  .line {
    transition: 300ms;
  }
}