:root {
  --foreground-rgb: 255, 255, 256;
  --glow: linear-gradient(
    to bottom right,
    rgba(1, 65, 255, 0),
    rgb(16 211 255 / 20%),
    rgb(216 0 255 / 50%)
  );
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

:not(img)::selection {
  background: #05c3dd;
}

img::selection {
  background: transparent;
}

img::moz-selection {
  background: transparent;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #fff;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

div[data-overlay-container='true'] {
  position: absolute;
  top: 0px;
  left: 0px;

  width: 100vw;
  height: 100vh;

  overflow-y: scroll;
  overflow-x: hidden;
}

.main {
  width: 100vw;
  height: -moz-fit-content;
  height: fit-content;
  overflow: scroll;
}

.Navbar_navbar__zhZYq {
  position: fixed;

  z-index: 4;

  width: 100vw;
  min-height: 76px;

  padding: 20px 40px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background: #fff;
}

.Navbar_tab__1RtJZ {
  font-size: 20px;
}

.Navbar_navbar__zhZYq .Navbar_inner__cgkbE {
  max-width: 1800px;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.Navbar_tabList__nCYtb {
  margin-left: auto;

  display: flex;
  flex-direction: row;
  gap: 24px;
}

.Navbar_navToggle__53jJz {
  width: 48px;
  height: 48px;

  box-shadow: 0 0 3px hsl(0deg 0% 0% / 30%), 0 0 2px hsl(0deg 0% 0% / 30%);

  margin-left: auto;

  padding: 13px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.Navbar_navToggle__53jJz:hover {
  cursor: pointer;
}

.Navbar_navToggle__53jJz:not(.Navbar_active__v_AMp):hover .Navbar_one__v5aGf {
  transform: translateX(-2px);
}

.Navbar_navToggle__53jJz:not(.Navbar_active__v_AMp):hover .Navbar_two__Z04_9 {
  transform: translateX(2px);
}

.Navbar_navToggle__53jJz.Navbar_active__v_AMp .Navbar_one__v5aGf {
  transform: translateY(6px) rotate(45deg);
}

.Navbar_navToggle__53jJz.Navbar_active__v_AMp .Navbar_two__Z04_9 {
  transform: translateY(-5px) rotate(-45deg);
}

.Navbar_navmenu__h0R6k {
  position: fixed;

  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #fff;

  z-index: 3;
}

.Navbar_navmenu__h0R6k .Navbar_inner__cgkbE {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 12px;
}

.Navbar_sectionItem__25IhP {
  font-size: 40px;
}

.Navbar_line__ON_gQ {
  width: 100%;
  height: 1px;

  background: #000;
}

.Navbar_link__koQk0 {
  color: #333;
}

.Navbar_link__koQk0:hover {
  color: #222;
}

.Navbar_activeLink__nIbaD {
  color: #111 !important;
}

@media screen and (max-width: 600px) {
  .Navbar_navmenu__h0R6k .Navbar_inner__cgkbE {
    flex-direction: column;
  }

  .Navbar_sectionList__utDHI {
    padding: 12px 0 0 0;

    align-items: center;
  }
}

@media screen and (max-width: 500px) {
  .Navbar_navbar__zhZYq {
    padding: 20px 16px;
  }
}

@media not (prefers-reduced-motion) {
  .Navbar_line__ON_gQ {
    transition: 300ms;
  }
}
/* cyrillic-ext */
@font-face {
  font-family: '__Roboto_792391';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7c53f7419436e04b-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Roboto_792391';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/1c57ca6f5208a29b-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Roboto_792391';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/cff529cd86cc0276-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Roboto_792391';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/7be645d133f3ee22-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Roboto_792391';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/5647e4c23315a2d2-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Roboto_792391';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3dbd163d3bb09d47-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Roboto_792391';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/934c4b7cb736f2a3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Roboto_Fallback_792391';src: local("Arial");ascent-override: 92.67%;descent-override: 24.39%;line-gap-override: 0.00%;size-adjust: 100.11%
}.__className_792391 {font-family: '__Roboto_792391', '__Roboto_Fallback_792391';font-weight: 400;font-style: normal
}

.Footer_footer__yl3k1 {
  width: 100%;

  background: #000;

  z-index: 2;
  position: relative;

  margin-bottom: auto;

  padding: 50px 0;
  margin-top: 100px;
}

.Footer_infoSection__1kVtT {
  display: flex;
}

.Footer_section__SrMcd,
.Footer_sectionList__uaC82 {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.Footer_sectionHeader__2ysg5 {
  letter-spacing: 2.5px;
  font-size: 24px;
}

.Footer_link__2dc7Z,
.Footer_text__R1oKh,
.Footer_navLink__X9Kzv {
  font-size: 16px;
  color: #ddd;
}

.Footer_link__2dc7Z:hover,
.Footer_navLink__X9Kzv:hover,
.Footer_activeLink__PCy3s {
  color: #fff;
}

.Footer_socialList__YZOsF {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  margin-top: 1vh;
}

.Footer_socialImage__sfeiJ {
  filter: contrast(0.6);
}

.Footer_socialImage__sfeiJ:hover {
  filter: contrast(1);
}

@media screen and (orientation: landscape), (min-width: 500px) {
  .Footer_infoSection__1kVtT {
    justify-content: center;
    flex-direction: row;
    column-gap: 4vw;
  }

  .Footer_sectionHeader__2ysg5 {
    margin-bottom: 1vh;
  }
}

@media screen and (orientation: portrait) and (max-width: 500px) {
  .Footer_infoSection__1kVtT {
    flex-direction: column;
    row-gap: 2.5vh;
  }

  .Footer_socialImage__sfeiJ {
    width: 24px !important;
    height: 24px !important;
  }

  .Footer_socialList__YZOsF {
    width: 60vw;
    max-width: 300px;
  }

  .Footer_link__2dc7Z,
  .Footer_text__R1oKh {
    text-align: center;
  }

  .Footer_section__SrMcd {
    align-items: center;
  }

  .Footer_sectionHeader__2ysg5 {
    text-align: center;

    margin-bottom: 0.25vh;
  }
}

/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_c51b09';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/0ff497dee15f2571-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_c51b09';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/deb571258919892a-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_c51b09';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/5f528682b9960db9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_c51b09';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/89887aa9272c142a-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_c51b09';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d2fe06a35597f3b2-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_c51b09';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/3da9bdc349922b12-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_c51b09';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/f3f50b890c106f83-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Source_Sans_3_Fallback_c51b09';src: local("Arial");ascent-override: 108.65%;descent-override: 42.44%;line-gap-override: 0.00%;size-adjust: 94.24%
}.__className_c51b09 {font-family: '__Source_Sans_3_c51b09', '__Source_Sans_3_Fallback_c51b09';font-weight: 700;font-style: normal
}

/* cyrillic-ext */
@font-face {
  font-family: '__Source_Sans_3_755843';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/74528949a461fbc4-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Sans_3_755843';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2e9ad48b48e8e3f1-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Sans_3_755843';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/4ec7d9b7092c4209-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Sans_3_755843';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/55ccfc3522c4f749-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Sans_3_755843';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2191f2c21322f697-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Sans_3_755843';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d3366980fbbe6ce3-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Sans_3_755843';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e8ef263ed940bf24-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Source_Sans_3_Fallback_755843';src: local("Arial");ascent-override: 108.65%;descent-override: 42.44%;line-gap-override: 0.00%;size-adjust: 94.24%
}.__className_755843 {font-family: '__Source_Sans_3_755843', '__Source_Sans_3_Fallback_755843';font-weight: 400;font-style: normal
}

